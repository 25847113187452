import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const VentesMatelasSectionWrapper = styled.div`
  width: 100%;
  position: relative;
`

const VentesContainer = styled.div`
  width: 100%;
  max-width: 1900px;
  margin: 0px auto;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ButtonTitle = styled.span`
  display: none;
`

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  margin: 0px 10px 0px 0px;

  @media (max-width: 767px) {
    margin: 0px 5px 0px 0px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const ChristmasTextWrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262626;

  @media (max-width: 600px) {
    padding: 20px;
  }
`

const Title = styled.div`
  font-size: 35px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;
  text-align: center;

  span {
    @media (max-width: 992px) {
      display: block;
    }
  }

  @media (max-width: 1600px) {
    font-size: 30px;
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }
`

const SubTitle = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: center;
  margin: 20px 0px 0px;

  strong {
    font-weight: bold;
  }

  span {
    font-size: 80px;
    line-height: 1;
    font-family: "Museo";
    font-weight: 900;
    display: block;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
    margin: 20px 0px 0px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileSubTitle = styled.div`
  display: none;
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: left;

  strong {
    font-weight: bold;
  }

  span {
    font-size: 36px;
    line-height: 1;
    font-family: "Museo";
    font-weight: 900;
    display: block;
  }

  @media (max-width: 600px) {
    display: block;
  }
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: bold;
  text-align: center;
  margin: 30px 0px;
  max-width: 480px;

  @media (max-width: 1600px) {
    font-size: 14px;
    margin: 20px 0px;
  }

  @media (max-width: 600px) {
    margin: 15px 0px;
    text-align: left;
  }
`

const BottomText = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  text-align: center;

  strong {
    font-weight: bold;
  }

  @media (max-width: 1600px) {
    font-size: 13px;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    text-align: left;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
  background: #e7f0f3;
  margin: 0px 0px 0px 10px;

  @media (max-width: 767px) {
    margin: 0px 0px 0px 5px;
  }

  @media (max-width: 600px) {
    margin: 0px;
  }
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 1;
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
`

const TopSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  min-height: 400px;

  @media (max-width: 1200px) {
    min-height: 350px;
  }

  @media (max-width: 850px) {
    min-height: 300px;
  }

  @media (max-width: 480px) {
    min-height: 240px;
  }
`

const MedicalImage = styled.img`
  width: 100%;
  max-width: 140px;
  margin: 0px;
  position: absolute;
  top: 10%;
  right: 10%;

  @media (max-width: 1600px) {
    max-width: 120px;
    top: 3%;
    right: 3%;
  }

  @media (max-width: 1200px) {
    max-width: 80px;
    top: 15px;
    right: 15px;
  }

  @media (max-width: 1024px) {
    top: 10px;
    right: 10px;
  }

  @media (max-width: 992px) {
    top: 20%;
    right: 20px;
  }

  @media (max-width: 600px) {
    top: 15%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0px 0px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

const Button = styled(Link)`
  color: #262626;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  border: 1px solid #262626;
  padding: 15px 20px;
  position: relative;
  margin: 0px;
  z-index: 1;

  :hover {
    color: #fff;
    background: #262626;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const MiddleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0px 0px;

  @media (max-width: 600px) {
    flex-direction: row;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-right: 20px;
  }
`

const LogoImage = styled.img`
  width: 100%;
  max-width: 120px;
  margin: ${({ order }) => (order === 2 ? "0px 0px 0px 20px" : "0px")};

  @media (max-width: 600px) {
    margin: ${({ order }) => (order === 2 ? "20px 0px 0px 0px" : "0px")};
    max-width: 100px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const VentesMatelasSection = ({ intl, data }) => {
  return (
    data && (
      <VentesMatelasSectionWrapper>
        <VentesContainer>
          <LeftSection>
            {data.url && (
              <AbsoluteButton to={data.url}>
                <ButtonTitle>Découvrir</ButtonTitle>
              </AbsoluteButton>
            )}
            <TopSection>
              {data.image && (
                <BannerImage src={data.image} alt={data.alt ? data.alt : ""} />
              )}
            </TopSection>
          </LeftSection>
          <RightSection>
            {data.url && (
              <AbsoluteButton to={data.url}>
                <ButtonTitle>Découvrir</ButtonTitle>
              </AbsoluteButton>
            )}
            {data.top_image && (
              <MedicalImage
                className="lazyload"
                data-src={data.top_image}
                alt={data.top_image_alt ? data.top_image_alt : ""}
              />
            )}
            <ChristmasTextWrapper>
              {data.title && (
                <Title
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
              )}
              {data.subtitle && (
                <SubTitle
                  dangerouslySetInnerHTML={{
                    __html: data.subtitle,
                  }}
                />
              )}
              <MiddleBox>
                <LogoWrapper>
                  <LogoImage src="https://static.percko.com/uploads/6750140d-28da-4993-bf84-a00b42d0e0c6.jpg" />
                  <LogoImage
                    src="https://static.percko.com/uploads/9faeb96f-d1ec-47cf-b812-bf9daab4692f.png"
                    order={2}
                  />
                </LogoWrapper>
                <TextWrapper>
                  {data.subtitle && (
                    <MobileSubTitle
                      dangerouslySetInnerHTML={{
                        __html: data.subtitle,
                      }}
                    />
                  )}
                  {data.text && (
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: data.text,
                      }}
                    />
                  )}
                  {data.bottom_text && (
                    <BottomText
                      dangerouslySetInnerHTML={{
                        __html: data.bottom_text,
                      }}
                    />
                  )}
                </TextWrapper>
              </MiddleBox>
              {data.url && (
                <ButtonWrapper>
                  <Button to={data.url}>Découvrir</Button>
                </ButtonWrapper>
              )}
            </ChristmasTextWrapper>
          </RightSection>
        </VentesContainer>
      </VentesMatelasSectionWrapper>
    )
  )
}

export default injectIntl(VentesMatelasSection)
